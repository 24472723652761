import React from "react"
import DeltaRatio from './delta-ratio'
import People from "@mui/icons-material/People";

function deltaComponent(fetchedTimeList, dataList,deltaRatio){
    let latestFetchTime = new Date(fetchedTimeList[fetchedTimeList.length-1] * 1000);
    let firstFetchTime = new Date(fetchedTimeList[0] * 1000);
    latestFetchTime.setHours(latestFetchTime.getHours() + 10);
    let diffTime = Math.abs(latestFetchTime - firstFetchTime);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))-1; 
    return (
        <div>
            <div class="enJeMd">
                <DeltaRatio deltaRatio = {deltaRatio}></DeltaRatio>
                {
                    (() => {
                    if(deltaRatio > 0){
                        return (<span class="Bc6y2 v7Yn8 Tr2v7">+{(dataList[dataList.length-1] - dataList[0]).toLocaleString()} {diffDays} 日</span>);
                    } else if(deltaRatio < 0){
                        return (<span class="Bc6y2 Nh8GP Tr2v7">{(dataList[dataList.length-1] - dataList[0]).toLocaleString()} {diffDays} 日</span>);
                    } else {
                        return (<span class="Bc6y2 hE2wU Tr2v7">0.00 {diffDays} 日</span>);
                    }
                    })()
                }
            </div>
        </div>
    );
}

const GCARD = ({ title,fetchedTimeList, latestValue,dataList,deltaRatio, ...props }) => {
    let t = new Date(fetchedTimeList[fetchedTimeList.length-1]*1000);
    t.setHours(t.getHours() + 9);
    let latestFetchTime = t.toLocaleDateString() + " " + t.toLocaleTimeString();
    return (        
        <div>
            <div class = "K7d9r">
                <div class="f9HxE"><People class = "css-i4bv87-MuiSvgIcon-lg"/>Follower</div>
            </div>
            <div class="iR48H">
                <div class="Sp74u">
                    <div>
                        <div class="iV3ef">
                            <span class="">
                                <div class="m5Ahj">
                                    <div class="Gg2Li W8ua6">{latestValue.toLocaleString()}</div>
                                </div>
                            </span>
                        </div>
                    </div>
                    {deltaComponent(fetchedTimeList, dataList, deltaRatio)}

                </div>
            </div>
        <div class="Xz7b6">{latestFetchTime}</div>
        </div>
    )
}

export default GCARD;