import * as React from 'react'
import Layout from './layout'
import SEO from './seo'
import LineWithTitle from './lineWithTitle'
import AccountHeader from './accountHeader'


const TiktokAccountPage = ({ pageContext, location }) => {
  // let pageimg = pageContext.data.avaterImage ? pageContext.data.avaterImage.childImageSharp.original.src : null;
  // let pageimgw = pageContext.data.avaterImage ? pageContext.data.avaterImage.childImageSharp.original.width : null;
  // let pageimgh = pageContext.data.avaterImage ? pageContext.data.avaterImage.childImageSharp.original.height : null;
  return (
    <Layout>
        <SEO 
        pagetitle={`${pageContext.data.account_name}(@${pageContext.data.unique_id})`}
        pagedesc={pageContext.data.character_words}
        pagepath={location.pathname}
        // pageimg={pageimg}
        // pageimgw={pageimgw}
        // pageimgh={pageimgh}
        />
        <div className="main">
        <div className="Q9vxw2">
          <AccountHeader props = {pageContext.data}></AccountHeader>
          <LineWithTitle title = "フォロワー数" type = "follower_count" data = {pageContext.data}></LineWithTitle>
        </div>
        </div>
    </Layout>
  )
}

export default TiktokAccountPage

