import React from 'react';
import {Line} from 'react-chartjs-2';
import GCARD from './gcard'
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale, Legend, Utils, Filler,Tooltip } from 'chart.js';
ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale, Legend, Filler, Tooltip);

function getGraphData(fetchedTimeList, dataList) {
  let y_list = [];
  let labels = [];
  for(let i = 0; i < dataList.length; i++){
    y_list.push(dataList[i]);
    var t = new Date(fetchedTimeList[i]*1000);
    labels.push([t.toLocaleDateString()])
  }
  const graphData= {
    labels: labels,
    datasets: [
      // 表示するデータセット
      {
        type: 'line',
        data: y_list,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgb(54, 162, 235)",
        fill: true,
      },
    ],
  };
  return graphData
}

class LineWithTitle extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    // this.targetPeriod = this.getTargetPeriod("_5");
    this.targetPeriod = "_5";
  }
  // getTargetPeriod(period) {
  //   console.log(this.targetPeriod);
  //   this.targetPeriod = period;
  //   this.title = this.props.title;
  //   this.fetchedTimeList = this.props.data.graphData[this.targetPeriod].fetched_time;
  //   this.latestValue = this.props.data.latestValues.follower_count;
  //   this.dataList = this.props.data.graphData[this.targetPeriod].follower_count;
  //   this.deltaRatio = this.props.data.deltaRatioValues[this.targetPeriod].follower_count;

  //   let samplingPoint = parseInt(this.fetchedTimeList.length/4);
  //   if(this.fetchedTimeList.length < 6){samplingPoint = 1;}
  //   return period;
  // }
  buttonAlert(){
    console.log(this.dataList)
    this.targetPeriod = "_30";
    this.title = "hogehuga";
    this.fetchedTimeList = this.props.data.graphData[this.targetPeriod].fetched_time;
    this.latestValue = this.props.data.latestValues.follower_count;
    this.dataList = this.props.data.graphData[this.targetPeriod].follower_count;
    this.deltaRatio = this.props.data.deltaRatioValues[this.targetPeriod].follower_count;

    let samplingPoint = parseInt(this.fetchedTimeList.length/4);
    if(this.fetchedTimeList.length < 6){samplingPoint = 1;}
    console.log(this.dataList)
    this.forceUpdate();
    // alert('Clicked!');
  }

  render() {
    
    this.title = this.props.title;
    this.fetchedTimeList = this.props.data.graphData[this.targetPeriod].fetched_time;
    this.latestValue = this.props.data.latestValues.follower_count;
    this.dataList = this.props.data.graphData[this.targetPeriod].follower_count;
    this.deltaRatio = this.props.data.deltaRatioValues[this.targetPeriod].follower_count;

    let samplingPoint = parseInt(this.fetchedTimeList.length/4);
    if(this.fetchedTimeList.length < 6){samplingPoint = 1;}
    return (
    <div>
      <GCARD title = {this.title} fetchedTimeList = {this.fetchedTimeList} latestValue = {this.latestValue} dataList = {this.dataList} deltaRatio = {this.deltaRatio}></GCARD>
      {/* <button onClick={() => this.buttonAlert()}>Click me</button> */}
      <div class="QcYZxe IwGbkd Bdfwze">
            <div class="NDTw3e LMp9Eb">
              <div class="dQlDUb">
                <div class="QiGJYb luQogd fw-ch-sel">
                  <div class="qUjgX IbhwJb">
                    <div>
                      <div>5 日</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dQlDUb">
                <div class="QiGJYb luQogd">
                  <div class="qUjgX IbhwJb">
                    <div aria-label="1か月">
                      <div>1 か月</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
      
      
      <Line data={getGraphData(this.fetchedTimeList, this.dataList)} options = {{
        interaction: {
          mode: 'index',
          intersect: false,
        },
        plugins:{
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            grid: {
              display : false
            },
            ticks: {
              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              callback: function(val, index) {
                // Hide every 2nd tick label
                return (index+1) % samplingPoint === 0 ? this.getLabelForValue(val) : '';
              },
            }
          }
        }
      }}/>
    </div>
    );
  }
}

// const LineWithTitle = ({ title,latestValue,type,fetchedTimeList, dataList,deltaRatio, ...props }) => {
//   let samplingPoint = parseInt(fetchedTimeList.length/4);
//   if(fetchedTimeList.length < 6){samplingPoint = 1;}

//   return (
//     <div>
//       <GCARD title = {title} fetchedTimeList = {fetchedTimeList} latestValue = {latestValue} dataList = {dataList} deltaRatio = {deltaRatio}></GCARD>
//       <div class="QcYZxe IwGbkd Bdfwze">
//             <div class="NDTw3e LMp9Eb">
//               <div class="dQlDUb">
//                 <div class="QiGJYb luQogd fw-ch-sel">
//                   <div class="qUjgX IbhwJb">
//                     <div aria-label="5 日">
//                       <div>5 日</div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div class="dQlDUb">
//                 <div class="QiGJYb luQogd">
//                   <div class="qUjgX IbhwJb">
//                     <div aria-label="1か月">
//                       <div>1 か月</div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//         </div>
      
      
//       <Line data={getGraphData(fetchedTimeList, dataList)} options = {{
//       interaction: {
//         mode: 'index',
//         intersect: false,
//       },
//       plugins:{
//         legend: {
//           display: false
//         }
//       },
//       scales: {
//         x: {
//           grid: {
//             display : false
//           },
//           ticks: {
//             // For a category axis, the val is the index so the lookup via getLabelForValue is needed
//             callback: function(val, index) {
//               // Hide every 2nd tick label
//               return (index+1) % samplingPoint === 0 ? this.getLabelForValue(val) : '';
//             },
//           }
//         }
//       }
//     }}/>
//     </div>
//   );
// }

export default LineWithTitle;