import React from "react"
import { GatsbyImage, getImage, StaticImage  } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExternalLinkAlt,
  faHashtag
} from "@fortawesome/free-solid-svg-icons"

export default ({props}) => {
    return (
<div class="Z3tSm a8GiU">
  <div class="A7ceX v4WaS">
    <div class="Xm8UZ">
      <span class="y8A2u">
      <GatsbyImage image={getImage(props.avaterImage)} alt={props.unique_id} />
            </span>
    </div>
    <div>
      <h2 class="mT64S">@{props.unique_id}
      <a href={props.profile_url} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" />
      </a>
      </h2>
      <h1 class="xW6TV">{props.account_name} 
      </h1>
    </div>
    <div class="xW6TV">

      </div>

    {/* <div class="tiktok-1rxc7na-DivShareActions e13s99ws8"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5546 8.35111L13.3171 8.16468V7.37972V3.50006L21.4998 12.0001L13.3171 20.5001V16.3738V15.3664L12.3098 15.3738C8.838 15.3994 5.4275 17.0466 2.49983 19.5882C2.54612 19.2536 2.67769 18.641 2.94391 17.8329C3.3786 16.5132 4.01326 15.1988 4.88691 13.971C6.71045 11.4083 9.24414 9.16046 12.5546 8.35111Z" stroke="#161823" stroke-width="2"></path></svg></div>
    <div data-e2e="user-more" class="tiktok-jzplnh-DivMoreActions e13s99ws6"><svg width="24" height="24" viewBox="0 0 48 48" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 24C4 21.7909 5.79086 20 8 20C10.2091 20 12 21.7909 12 24C12 26.2091 10.2091 28 8 28C5.79086 28 4 26.2091 4 24ZM20 24C20 21.7909 21.7909 20 24 20C26.2091 20 28 21.7909 28 24C28 26.2091 26.2091 28 24 28C21.7909 28 20 26.2091 20 24ZM36 24C36 21.7909 37.7909 20 40 20C42.2091 20 44 21.7909 44 24C44 26.2091 42.2091 28 40 28C37.7909 28 36 26.2091 36 24Z"></path></svg></div> */}

  </div>
  {/* <h2 class="tiktok-7k173h-H2CountInfos e1awr0pt0">
    <div class="Ba47x e1awr0pt1"><strong title="フォロー中" data-e2e="following-count">12</strong><span data-e2e="following" class="z9A62 e1awr0pt2">フォロー中</span></div>
    <div class="Ba47x e1awr0pt1"><strong title="フォロワー" data-e2e="followers-count">20.3K</strong><span data-e2e="followers" class="z9A62 e1awr0pt2">フォロワー</span></div>
    <div class="tiktok-xeexlu-DivNumber e1awr0pt1"><strong title="いいね" data-e2e="likes-count">540K</strong><span data-e2e="likes" class="z9A62 e1awr0pt2">いいね</span></div>
  </h2> */}
  <h2 class="Js6NB">{props.description}</h2>
  <h2 class="Js6NB"><FontAwesomeIcon icon={faHashtag} size="sm" />  {props.character_words}</h2>
  <div class="c8C5x"></div>
  </div>

);

}